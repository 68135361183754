import React, { useState, useEffect } from 'react'

const SectionQuarterPolygons = ({ map, corners }) => {
  const [polygons, setPolygons] = useState()

  useEffect(() => {
    setPolygons(corners.map(quarter => {
      return new google.maps.Polyline(
      {
        path: quarter,
        strokeWeight: 1,
        strokeOpacity: 0.2
      }
    )}))
  }, [map]);

  useEffect(() => {
    map && polygons && polygons.map(polygon => polygon.setMap(map))
  }, [map, polygons]);

  return null
}

export default SectionQuarterPolygons
