import React from 'react'

const AtsResult = ({ atsOffset }) => {
  const section_quarter = atsOffset.section_quarter

  return <>
    <div className="converter-results-section w-1/4">
      <div className="uppercase bg-gray-100 px-2 py-1">
        {atsOffset.quarter_lsd ? "STS" : "ATS"}
      </div>
      <div className="coordinate-group">
        <div className="coordinate-record">
          <div className="coordinate-label">Quarter:</div>
          <div className="coordinate-value">
            {section_quarter.quarter}
          </div>
        </div>
        {atsOffset.quarter_lsd ?
          <div>
            <div className="coordinate-hr" />
            <div className="coordinate-record">
              <div className="coordinate-label">1/4 LSD:</div>
              <div className="coordinate-value">
                {`${atsOffset.quarter_quarter_lsd}${atsOffset.quarter_lsd}`}
              </div>
            </div>
          </div> : <></>
        }
        <div className="coordinate-hr" />
        <div className="coordinate-record">
          <div className="coordinate-label">LSD:</div>
          <div className="coordinate-value">
            {atsOffset.lsd}
          </div>
        </div>
        <div className="coordinate-hr" />
        <div className="coordinate-record">
          <div className="coordinate-label">Section:</div>
          <div className="coordinate-value">
            {atsOffset.section}
          </div>
        </div>
        <div className="coordinate-hr" />
        <div className="coordinate-record">
          <div className="coordinate-label">Township:</div>
          <div className="coordinate-value">
            {atsOffset.township}
          </div>
        </div>
        <div className="coordinate-hr" />
        <div className="coordinate-record">
          <div className="coordinate-label">Range:</div>
          <div className="coordinate-value">
            {atsOffset.range}
          </div>
        </div>
        <div className="coordinate-hr" />
        <div className="coordinate-record">
          <div className="coordinate-label">Meridian:</div>
          <div className="coordinate-value">
            {atsOffset.meridian}
          </div>
        </div>
        <div className="coordinate-hr" />
        <div className="coordinate-record">
          <div className="coordinate-label">N/S</div>
          <div className="coordinate-value">
            {atsOffset.offset_y} {atsOffset.offset_y_direction}
          </div>
        </div>
        <div className="coordinate-hr" />
        <div className="coordinate-record">
          <div className="coordinate-label">E/W</div>
          <div className="coordinate-value">
            {atsOffset.offset_x} {atsOffset.offset_x_direction}
          </div>
        </div>
      </div>
    </div>
  </>
}
export default AtsResult
