import React from 'react'
import Coordinate from "./coordinate";
import AtsResult from "./atsResult";
import { formatDms } from "./dms";

const Results = ({ result }) => {
  const nad83_coordinate = result.nad83_coordinate
  const nad27_coordinate = result.nad27_coordinate
  const ats_offset = result.ats_offset

  return <div>
    <div className="flex flex-col">
      <div className="result-header">
        <div className="p-2 text-lg font-semibold bg-gray-300 rounded-t-md">
          {result.latitude}, {result.longitude}
        </div>
      </div>
      <div className="result-data flex flex-row gap-x-4">
        <Coordinate coordinate={nad83_coordinate} />
        <Coordinate coordinate={nad27_coordinate} />
        {
          ats_offset ? <AtsResult atsOffset={ats_offset} /> : <div className="converter-results-section">
            <div className="coordinate-group">
              <div className="flex flex-row justify-between px-1">
                <div className="coordinate-label">ATS Section not found</div>
              </div>
            </div>
          </div>
        }
      </div>
      <div className='flex flex-row justify-between bg-gray-100 py-1 px-2'>
        <div className="flex flex-row items-center">
          <div className="coordinate-label mr-4 w-48">Meridian Convergence:</div>
          <div
            className="coordinate-value">{result.meridian_convergence ? formatDms(result.meridian_convergence) : ''}</div>
        </div>
        <div className="flex flex-row items-center">
          <div className="coordinate-label w-48">Scale Factor:</div>
          <div className="coordinate-value">
            {result.scale_factor}
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Results
