import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AtsConverter from "./atsConverter";
import Converter from "./converter";
import LatLonDecimalConverter from "./latLonDecimalConverter";
import UtmConverter from "./utmConverter";
import LatLonDmsConverter from "./latLonDmsConverter";

const Main = () => {
  return (
    <Router>
      <Switch>
        <Route path='/utm'>
          <Converter type='utm'>
            <UtmConverter />
          </Converter>
        </Route>
        <Route path='/decimal'>
          <Converter type='decimal'>
            <LatLonDecimalConverter />
          </Converter>
        </Route>
        <Route path='/dms'>
          <Converter type='dms'>
            <LatLonDmsConverter />
          </Converter>
        </Route>
        <Route path='/ats'>
          <Converter type='ats'>
            <AtsConverter />
          </Converter>
        </Route>
        <Route path='/'>
          <Converter type='decimal'>
            <LatLonDecimalConverter />
          </Converter>
        </Route>
      </Switch>
    </Router>
  )
}

export default Main
