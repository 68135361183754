import React, { useContext, useState } from 'react'
import axios from "axios";
import { ConverterContext } from "./converter";
import DatumSelect from "./datumSelect";

const LatLonDmsConverter = ({}) => {
  let [datumId, setDatumId] = useState('')

  let [latDegrees, setLatDegrees] = useState(null)
  let [latMinutes, setLatMinutes] = useState(null)
  let [latSeconds, setLatSeconds] = useState(null)

  let [lonDegrees, setLonDegrees] = useState(null)
  let [lonMinutes, setLonMinutes] = useState(null)
  let [lonSeconds, setLonSeconds] = useState(null)

  let { setResult } = useContext(ConverterContext)

  const submit = () => {
    axios.post('/dms.json', {
      input_dms: {
        datum: datumId,
        latitude_d: latDegrees,
        latitude_m: latMinutes,
        latitude_s: latSeconds,
        longitude_d: lonDegrees,
        longitude_m: lonMinutes,
        longitude_s: lonSeconds,
      }
    }).then(res => setResult(res.data.conversion_result))
  }

  return <div className='flex flex-col'>
    <div className="flex flex-col gap-y-4 items-center">
      <DatumSelect datumId={datumId} setDatumId={setDatumId} />

      <div className="flex flex-row gap-x-2 items-center">
        <h2 className='w-24'>Latitude</h2>
        <div className='flex flex-row'>
          <input type='text' className='border border-gray-300 rounded-md p-2 w-24'
                 value={latDegrees}
                 onChange={event => setLatDegrees(event.target.value)}
          />
          <span className="text-xl mx-1">&deg;</span>
        </div>
        <div className='flex flex-row'>
          <input type='text' className='border border-gray-300 rounded-md p-2 w-24'
                 value={latMinutes}
                 onChange={event => setLatMinutes(event.target.value)}
          />
          <span className="text-xl mx-1">'</span>
        </div>
        <div className='flex flex-row'>
          <input type='text' className='border border-gray-300 rounded-md p-2 w-24'
                 value={latSeconds}
                 onChange={event => setLatSeconds(event.target.value)}
          />
          <span className="text-xl mx-1">"</span>
        </div>
        <div className='text-lg'>North</div>
      </div>
      <div className="flex flex-row gap-x-2 items-center">
        <h2 className='w-24'>Longitude</h2>
        <div className='flex flex-row'>
          <input type='text' className='border border-gray-300 rounded-md p-2 w-24'
                 value={lonDegrees}
                 onChange={event => setLonDegrees(event.target.value)}
          />
          <span className="text-lg mx-1">&deg;</span>
        </div>
        <div className='flex flex-row'>
          <input type='text' className='border border-gray-300 rounded-md p-2 w-24'
                 value={lonMinutes}
                 onChange={event => setLonMinutes(event.target.value)}
          />
          <span className="text-lg mx-1">'</span>
        </div>
        <div className='flex flex-row'>
          <input type='text' className='border border-gray-300 rounded-md p-2 w-24'
                 value={lonSeconds}
                 onChange={event => setLonSeconds(event.target.value)}
          />
          <span className="text-lg mx-1">"</span>
        </div>
        <div className='text-lg'>West</div>
      </div>
      <div className='my-4 px-4 py-2 bg-blue-400 rounded-md cursor-pointer w-48 text-center'
           onClick={() => submit()}>Submit
      </div>
    </div>
  </div>
}

export default LatLonDmsConverter
