import React, { useState, useEffect, useRef, useContext } from 'react'
import { ConverterContext } from "./converter";
import CoordinateMarker from "./coordinateMarker";
import SectionPolygon from "./sectionPolygon";
import SectionQuarterPolygons from "./sectionQuarterPolygons";

const Map = () => {
  let [center, setCenter] = useState(null)
  let [zoom, setZoom] = useState(11)
  let [map, setMap] = useState(null)

  let { result } = useContext(ConverterContext)

  const ref = useRef();

  useEffect(() => {
    if (result) {
      setCenter({ lat: parseFloat(result.latitude), lng: parseFloat(result.longitude) })
      setZoom(13)
    }
  }, [result])

  useEffect(() => {
    center && setMap(
      new window.google.maps.Map(ref.current, {
        center: center,
        zoom: zoom
      })
    )
  }, [center, result])

  useEffect(() => {
    map && map.setZoom(zoom)
  }, [zoom])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position) {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      },
      function(error) {
        if (error.code == error.PERMISSION_DENIED)
          setCenter({
            lat: 51.05367771445962,
            lng: -114.06894426264068
          });
      })
  }, [ref])

  return (
    <div
      ref={ref}
      style={{ width: 'auto', height: '100%' }}
    >
      {result && <CoordinateMarker map={map} latitude={result.latitude}
                                   longitude={result.longitude} />}
      {result?.ats_offset && <SectionPolygon map={map} corners={result.ats_offset.section_polygon} />}
      {result?.ats_offset && <SectionQuarterPolygons map={map} corners={result.ats_offset.section_quarters_polygons} />}
    </div>
  )
}

export default Map
