import React, { useContext, useState } from 'react'
import axios from "axios";
import { ConverterContext } from "./converter";
import DatumSelect from "./datumSelect";

const UtmConverter = ({}) => {
  let [datumId, setDatumId] = useState('')
  let [northing, setNorthing] = useState(null)
  let [easting, setEasting] = useState(null)
  let [zone, setZone] = useState(11)

  let { setResult } = useContext(ConverterContext)

  const submit = () => {
    axios.post('/utm.json', {
      input_utm: {
        datum: datumId,
        northing: northing,
        easting: easting,
        zone: zone
      }
    }).then(res => setResult(res.data.conversion_result))
  }

  return <div className="flex flex-col gap-y-2 items-center">
    <DatumSelect datumId={datumId} setDatumId={setDatumId} />
    <div className='flex flex-col gap-y-2 items-center'>
      <div className='flex flex-row gap-x-2 items-center'>
        <div className='w-24'>Northing</div>
        <input type="text" className='border border-gray-300 rounded-md p-2 w-48'
               value={northing}
               onChange={event => setNorthing(event.target.value)}
        />
      </div>
      <div className='flex flex-row gap-x-2 items-center'>
        <div className='w-24'>Easting</div>
        <input type="text" className='border border-gray-300 rounded-md p-2 w-48'
               value={easting}
               onChange={event => setEasting(event.target.value)}
        />
      </div>
      <div className='flex flex-row gap-x-2 items-center'>
        <div className='w-24'>Zone</div>
        <select className='border border-gray-300 rounded-md p-2 w-48'
                onChange={event => setZone(event.target.value)}>
          <option value={10}>9</option>
          <option value={10}>10</option>
          <option value={11} selected>11</option>
          <option value={12}>12</option>
          <option value={13}>13</option>
          <option value={14}>14</option>
        </select>
      </div>
    </div>
    <div className='my-4 px-4 py-2 bg-blue-400 rounded-md cursor-pointer w-48 text-center'
         onClick={() => submit()}>Submit
    </div>
  </div>
}

export default UtmConverter
