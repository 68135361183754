import React, { useState, useEffect } from 'react'

const CoordinateMarker = ({ latitude, longitude, map }) => {
  const [marker, setMarker] = useState()

  useEffect(() => {
    setMarker(new google.maps.Marker())
  }, [map]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
        map: map,
      });
    }
  }, [marker]);

  return null
}

export default CoordinateMarker
