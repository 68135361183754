import React, { useEffect, useState } from 'react'
import axios from "axios";

const DatumSelect = ({ datumId, setDatumId }) => {
  let [datums, setDatums] = useState([])

  useEffect(() => {
    axios.get('/datums.json').then(res => {
      setDatums(res.data)
      setDatumId(res.data[0].id)
    })
  }, [])

  return <div className='flex flex-row w-full my-2 justify-between'>
    <div className='text-lg font-bold w-24'>Convert:</div>
    <select className='border border-gray-400 rounded-md text-lg p-2 w-48'
            onChange={event => setDatumId(event.target.value)}>
      <option value="" disabled selected hidden></option>
      {datums.map(datum => <option key={datum.id} value={datum.id} selected={datum.id === datumId}>{datum.name}</option>)}
    </select>
  </div>
}

export default DatumSelect
