import React from 'react'
import { formatDms } from "./dms";

const Coordinate = ({ coordinate }) => {
  return <>
    <div className="converter-results-section w-1/4">
      <div className="uppercase bg-gray-100 px-2 py-1">
        {coordinate.datum.name}
      </div>
      <div className="py-1">
        <div className="coordinate-record border-b border-gray-100">
          <div className="coordinate-label">Latitude:</div>
          <div className="coordinate-value">
            {coordinate.latlon_decimal.latitude}
          </div>
        </div>
        <div className="coordinate-record">
          <div className="coordinate-label">Longitude:</div>
          <div className="coordinate-value">
            {coordinate.latlon_decimal.longitude}
          </div>
        </div>
      </div>

      <div className="py-1">
        <div className="coordinate-record border-b border-gray-100">
          <div className="coordinate-label">Latitude:</div>
          <div className="coordinate-value">
            {formatDms(coordinate.latlon_dms.latitude)}
          </div>
        </div>
        <div className="coordinate-record">
          <div className="coordinate-label">Longitude:</div>
          <div className="coordinate-value">
            {formatDms(coordinate.latlon_dms.longitude)}
          </div>
        </div>
      </div>
      <div className="py-1">
        <div className="coordinate-record border-b border-gray-100">
          <div className="coordinate-label">Northing:</div>
          <div className="coordinate-value">
            {coordinate.utm.northing}
          </div>
        </div>
        <div className="coordinate-record border-b border-gray-100">
          <div className="coordinate-label">Easting:</div>
          <div className="coordinate-value">
            {coordinate.utm.easting}
          </div>
        </div>
        <div className="coordinate-record">
          <div className="coordinate-label">Zone:</div>
          <div className="coordinate-value">
            {coordinate.utm.zone}
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Coordinate
