import React, { useState } from 'react'
import Results from "./results";
import { Link } from "react-router-dom";
import Map from "./map";
import { Wrapper } from "@googlemaps/react-wrapper";

export const ConverterContext = React.createContext({
  result: null
})

const Converter = ({ type, children }) => {
  let [result, setResult] = useState(null);

  return (
    <ConverterContext.Provider
      value={{ result: result, setResult: setResult }}>
      <div className='flex flex-row w-full flex-grow'>
        <div className='w-2/3 overflow-scroll flex flex-col justify-between'>
          <div className='flex flex-row'>
            <div className='flex flex-col justify-between px-8 py-4'>
              <Link to='/decimal'
                    className={`w-64 h-16 px-4 py-2 flex flex-col justify-end border-b-8 ${type === 'decimal' ? 'text-red-800 font-bold border-blue-500' : 'border-gray-200'}`}>
                <div className='text-center text-lg'>Latitude / Longitude</div>
                <div className='text-center text-xs'>Decimal</div>
              </Link>
              <Link to='/dms'
                    className={`w-64 h-16 px-4 py-2 flex flex-col justify-end border-b-8  ${type === 'dms' ? 'text-red-800 font-bold border-blue-500' : 'border-gray-200'}`}>
                <div className='text-center text-lg'>Latitude / Longitude</div>
                <div className='text-center text-xs'>Deg : Min : Sec</div>
              </Link>
              <Link to='/utm'
                    className={`w-64 h-16 px-4 py-2 text-xl justify-end text-center flex flex-col border-b-8 ${type === 'utm' ? 'text-red-800 font-bold border-blue-500' : 'border-gray-200'}`}>
                <div>UTM</div>
              </Link>
              <Link to='/ats'
                    className={`w-64 h-16 px-4 py-2 text-xl justify-end text-center flex flex-col border-b-8  ${type === 'ats' ? 'text-red-800 font-bold border-blue-500' : 'border-gray-200'}`}>
                <div>Township</div>
              </Link>
            </div>
            <div className='flex flex-col flex-grow p-4'>{children}</div>
          </div>
          <div className='flex flex-col gap-y-8 py-2 justify-center'>
            {result ? <Results result={result} /> : <></>}
          </div>
        </div>
        <div className='w-1/3'>
          <Wrapper apiKey={process.env.GOOGLE_API_KEY}>
            <Map />
          </Wrapper>
        </div>
      </div>
    </ConverterContext.Provider>
  )
}

export default Converter
