import React, { useContext, useState } from 'react'
import axios from "axios";
import { ConverterContext } from "./converter";
import DatumSelect from "./datumSelect";

const LatLonDecimalConverter = ({}) => {
  let [latitude, setLatitude] = useState(null)
  let [longitude, setLongitude] = useState(null)
  let [datumId, setDatumId] = useState('')

  let { setResult } = useContext(ConverterContext)

  const submit = () => {
    axios.post('/decimal.json', {
      input_decimal: {
        datum: datumId,
        latitude: latitude,
        longitude: longitude
      }
    }).then(res => setResult(res.data.conversion_result))
  }

  return <div className='flex flex-col'>
    <div className="flex flex-col gap-y-4 items-center">
      <DatumSelect datumId={datumId} setDatumId={setDatumId} />
      <div className='flex flex-col gap-y-2 justify-center items-center'>
        <div className="flex flex-row items-center justify-between">
          <div className='w-24 text-left'>Latitude</div>
          <div className='flex flex-row gap-x-2 items-center'>
            <input type="text" className='border border-gray-300 rounded-md p-2 w-48'
                   value={latitude}
                   onChange={event => setLatitude(event.target.value)}
            />
            <div className='text-xl w-8'>N</div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className='w-24 text-left'>Longitude</div>
          <div className='flex flex-row gap-x-2 items-center'>
            <input type="text" className='border border-gray-300 rounded-md p-2 w-48'
                   value={longitude}
                   onChange={event => setLongitude(event.target.value)} />
            <div className='text-xl w-8'>W</div>
          </div>
        </div>
        <div className='my-4 px-4 py-2 bg-blue-400 rounded-md cursor-pointer w-48 text-center'
             onClick={() => submit()}>Submit
        </div>
      </div>
    </div>

    <div>
      <div className="info text-xs">
        <div className="info-icon"></div>
        For longitudes ranging between 44 and 88 degrees WEST, latitude values should be between 40 and 84 degrees
        NORTH.
      </div>
      <div className="info text-xs">
        <div className="info-icon"></div>
        For longitudes ranging between 88 and 142 degrees WEST, latitude values should be between 47 and 84 degrees
        NORTH.
      </div>
    </div>
  </div>
}

export default LatLonDecimalConverter
