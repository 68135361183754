import React, { useState, useEffect } from 'react'

const SectionPolygon = ({ map, corners }) => {
  const [polygon, setPolygon] = useState()

  useEffect(() => {
    setPolygon(new google.maps.Polyline(
      {
        path: corners
      }
    ))
  }, [map]);

  useEffect(() => {
    polygon && polygon.setMap(map)
  }, [polygon]);

  return null
}

export default SectionPolygon
