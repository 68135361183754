import React, { useContext, useState } from 'react'
import axios from "axios";
import { ConverterContext } from "./converter";

const AtsConverter = () => {
  let [section, setSection] = useState(null)
  let [township, setTownship] = useState(null)
  let [range, setRange] = useState(null)
  let [meridian, setMeridian] = useState(null)
  let [offsetY, setOffsetY] = useState(null)
  let [offsetX, setOffsetX] = useState(null)
  let [offsetYDirection, setOffsetYDirection] = useState('')
  let [offsetXDirection, setOffsetXDirection] = useState('')

  let { setResult } = useContext(ConverterContext)

  const submit = () => {
    axios.post('/ats.json', {
      input_ats: {
        section: section,
        township: township,
        range: range,
        meridian: meridian,
        offset_x: offsetX,
        offset_x_direction: offsetXDirection,
        offset_y: offsetY,
        offset_y_direction: offsetYDirection
      }
    }).then(res => setResult(res.data.conversion_result))
  }

  return <div>
    <div className="flex flex-col items-center">
      <div className="flex flex-row my-2 gap-x-2 items-center justify-between">
        <div className='flex flex-row gap-x-2 items-center gap-x-2'>
          <div className='text-lg'>Sec</div>
          <input type="text" className='border border-gray-300 rounded-md p-2 w-24'
                 value={section}
                 onChange={event => setSection(event.target.value)}
          />
        </div>
        <div className='flex flex-row gap-x-2 items-center gap-x-2'>
          <div className='text-lg'>Twp</div>
          <input type="text" className='border border-gray-300 rounded-md p-2 w-24'
                 value={township}
                 onChange={event => setTownship(event.target.value)}
          />
        </div>
        <div className='flex flex-row gap-x-2 items-center gap-x-2'>
          <div className='text-lg'>Rng</div>
          <input type="text" className='border border-gray-300 rounded-md p-2 w-24'
                 value={range}
                 onChange={event => setRange(event.target.value)}
          />
        </div>
        <div className='flex flex-row gap-x-2 items-center gap-x-2'>
          <div className='text-lg'>M</div>
          <input type="text" className='border border-gray-300 rounded-md p-2 w-24'
                 value={meridian}
                 onChange={event => setMeridian(event.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-row my-2 gap-x-4 items-center justify-between">
        <div className='flex flex-row gap-x-2 items-center gap-x-2'>
          <div className='text-lg'>N/S</div>
          <input type="text" className='border border-gray-300 rounded-md p-2 w-24'
                 value={offsetY}
                 onChange={event => setOffsetY(event.target.value)}
          />
          <select className='border border-gray-400 rounded-md w-24 text-xl'
                  onChange={event => setOffsetYDirection(event.target.value)}
          >
            <option value="" disabled selected hidden></option>
            <option value={'N'}>N</option>
            <option value={'S'}>S</option>
          </select>
        </div>
        <div className='flex flex-row gap-x-2 items-center gap-x-2'>
          <div className='text-lg'>E/W</div>
          <input type="text" className='border border-gray-300 rounded-md p-2 w-24'
                 value={offsetX}
                 onChange={event => setOffsetX(event.target.value)}
          />
          <select className='border border-gray-400 rounded-md w-24 text-xl'
                  onChange={event => setOffsetXDirection(event.target.value)}
          >
            <option value="" disabled selected hidden></option>
            <option value={'E'}>E</option>
            <option value={'W'}>W</option>
          </select>
        </div>
      </div>
      <div className='my-4 px-4 py-2 bg-blue-400 rounded-md cursor-pointer w-48 text-center'
           onClick={() => submit()}>Submit
      </div>
    </div>
  </div>
}

export default AtsConverter
